import resume from '../assets/pdf/resume.pdf'
import user from '../assets/png/user.jpg'

export const headerData = {
    name: 'Sofyan Syah',
    title: "Web Developer",
    desciption:" Crafting dynamic and responsive websites that not only look great but also perform exceptionally. Explore my work, and let’s turn your ideas into reality.",
    image: user,
    resumePdf: resume
}
