export const experienceData = [
    {
        id: 1,
        company: 'Reinovasi Zona Kreasi Indonesia',
        jobtitle: 'Technical Support',
        startYear: '2016',
        endYear: '2017'
    },
    {
        id: 2,
        company: 'Temprina Media Grafika',
        jobtitle: 'Account Receivable and Warehouse Staff',
        startYear: '2017',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'Freelancer',
        jobtitle: 'Backend and Front End Developer',
        startYear: '2020',
        endYear: 'Present'
    },
]