export const educationData = [
    {
        id: 1,
        institution: '103 Jakarta Middle High School',
        course: 'Higher Secondary Education',
        startYear: '2003',
        endYear: '2006'
    },
    {
        id: 2,
        institution: '39 Jakarta Senior High School',
        course: 'Senior Secondary Education',
        startYear: '2006',
        endYear: '2009'
    },
    {
        id: 3,
        institution: 'Diponegoro University, Semarang',
        course: 'Bachelor Degree',
        startYear: '2009',
        endYear: '2014'
    },
]